<template>
    <div>
      <!-- <div class="d-flex justify-content-start align-items-center my-10">
          Toplam {{ totalItems }} müşteri bulundu.
        </div> -->
      <div
        class="d-flex align-items-center justify-content-between search-list-area"
      >
        <div class="d-flex align-items-center search-input mb-4">
          <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
          <select v-model="list_length" class="form-control ml-2">
            <option value="5" selected>Seçiniz</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
        <div>
          <div></div>
        </div>
      </div>
  
      <div class="custom-list">
        <List
          :header="header"
          :data="items"
          :pagination="pagination"
          :loading="loading"
          @updatePage="updatePage"
          moduleName="Müşteri Listesi"
          :moduleCreateEvent="true"
          :moduleSearch="search"
          @selectedItemIdsChanged="selectedItemIdsChanged"
          @openCreateModal="openInsertUpdate('')"
        >
          <template v-slot:item.IsActive="{ item }">
            <b-badge variant="success" v-if="item.IsActive === true"
              >Oluşturuldu</b-badge
            >
            <b-badge variant="danger" v-else>Oluşturulmadı</b-badge>
          </template>
          <template v-slot:item.IsDeleted="{ item }">
            <b-badge variant="success" v-if="item.IsDeleted === true"
              >Oluşturuldu</b-badge
            >
            <b-badge variant="danger" v-else>Oluşturulmadı</b-badge>
          </template>
          
          <template v-slot:item.vekaletname="{ item }">
            <b-badge variant="success" v-if="item.IsActive === true">Aktif</b-badge>
            <b-badge variant="danger" v-else>Pasif</b-badge>
        </template>
          <template v-slot:item.musteriBilgileri="{ item }">
          <b-button
            style="
              font-size: 11px;
              background-color: rgba(70, 179, 79, 0.726)!important;
              color: white;
            "
            variant="light"
            v-b-modal.modal-1
            >Kimlik Bilgileri</b-button
          >
        </template>
          <template v-slot:item.policeDetay="{ item }">
          <b-button
            style="
              font-size: 11px;
              background-color: rgba(255, 17, 0, 0.562)!important;
              color: white;
            "
            variant="light"
            v-b-modal.modal-2
            >Poliçe Bilgileri</b-button
          >
        </template>
     
          <template v-slot:item.action="{ item }" class="p-0">
            <b-dropdown
              size="sm"
              no-caret
              dropleft
              variant="muted"
              style="z-index: 999"
            >
              <template v-slot:button-content>
                <i class="ki ki-bold-more-ver text-dark"></i>
              </template>
              <b-dropdown-item @click="openDetail(item)">Detay</b-dropdown-item>
              <b-dropdown-item @click="openInsertUpdate(item)"
                >Düzenle</b-dropdown-item
              >
              <b-dropdown-item
                v-if="item.IsDeleted === false"
                @click="openDeleteHandler(item)"
                >Sil</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template v-slot:item.sigortaTuru="{ item }" class="p-0">
            <div class="row">
            <div class="col-12">
                <div class="form-group">
                <label for="policeBaslangic">Poliçe Başlangıç Tarihi</label>
                    <select name="" id="" class="form-control">
                        <option value="0">Sigorta 1</option>
                        <option value="1">Sigorta 2</option>
                        <option value="2">Sigorta 3</option>
                    </select>
            </div>
            </div>
            </div>
            </template>
          <template v-slot:item.sonOdeme="{item}">
              <b-badge  v-b-popover.hover.top="'Ödeme Tarihi Gecikti!'" style="cursor: pointer;" pill variant="warning" class="p-3"><i class="fas fa-exclamation fa-sm mr-4" style="color: red;"></i> {{ item.sonOdeme }}</b-badge>
          </template>
        </List>
        <InsertUpdateModal
          :title="
            selectedItem == ''
              ? 'Müşteri Ödeme Kaydı Ekle'
              : 'Müşteri Ödemeyi Düzenle'
          "
          :show="showInsertUpdate"
          @closeDetail="closeInsertUpdate()"
          @confirm="confirmModal()"
          :resetForm="resetForm"
        >
          <InsertUpdateOperations
            :selectedItem="selectedItem"
            :confirmModal="confirmCreate"
            @resetInsertUpdateForm="resetInsertUpdateForm"
          />
        </InsertUpdateModal>
  
        <DetailModal
          title="Müşteri Detayı"
          :show="showDetail"
          @openInsertUpdate="openInsertUpdate(selectedItem)"
          @closeDetail="closeDetail"
        >
          <DetailView :selectedItem="selectedItem" />
        </DetailModal>
  
        <ImportModal
          :show="showImportModal"
          importApiEndpoint="user/import"
          @closeDetail="closeImportModal()"
          @updateList="getList"
          @downloadSampleFile="exportOperations(true)"
        />
  
        <DeleteOperation
          :url="deleteOperationUrl"
          :params="deleteIds"
          @updateList="getList"
          modalid="deleteCustomer"
        />
        <!-- Create and Actions Popups -->
      </div>
  
      <div>
        <b-modal v-model="openFullTextModal" title="BootstrapVue">
          <p class="my-4">Hello from modal!</p>
        </b-modal>
  
        <b-modal id="modal-1" title="Kullanıcı Bilgileri">
         <div class="row">
            <div class="col-6">
            <div class="form-group">
            <label for="tckimlik">TC Kimlik Numarası</label>
            <input type="text" class="form-control" id="tckimlik" value="36321298753" disabled>
            </div>
            </div>
            <div class="col-6">
            <div class="form-group">
            <label for="adsoyad">Ad Soyad</label>
            <input type="text" class="form-control" id="adsoyad" value="Mert Önal" disabled>
            </div>
            </div>
            <div class="col-6">
            <div class="form-group">
            <label for="babaadi">Baba Adı</label>
            <input type="text" class="form-control" id="babaadi" value="Baba Önal" disabled>
            </div>
            </div>
            <div class="col-6">
            <div class="form-group">
            <label for="anneadi">Anne Adı</label>
            <input type="text" class="form-control" id="anneadi" value="Anne Önal" disabled>
            </div>
            </div>
            <div class="col-6">
            <div class="form-group">
            <label for="dogumtarihi">Doğum Tarihi</label>
            <input type="text" class="form-control" id="dogumtarihi" value="12.09.2001" disabled>
            </div>
            </div>
            <div class="col-6">
            <div class="form-group">
            <label for="dogumyeri">Doğum Yeri</label>
            <input type="text" class="form-control" id="dogumyeri" value="İstanbul" disabled>
            </div>
            </div>
        </div>
          <template #modal-footer="{ ok, cancel, hide }">
          <b-button size="sm" variant="success" @click="ok()">
          Tamam
           </b-button>
      </template>
        </b-modal>
        <b-modal id="modal-2" title="Poliçe Bilgileri">
            <div class="row">
            <div class="col-6">
                <div class="form-group">
                <label for="policeBaslangic">Poliçe Başlangıç Tarihi</label>
                <input type="date" class="form-control" id="policeBaslangic" >
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                <label for="policeBitis">Poliçe Bitiş Tarihi</label>
                <input type="date" class="form-control" id="policeBitis" >
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                <label for="policeBitis">Poliçe Tipi</label>
                <select name="" id="" class="form-control">
                    <option value="0">1</option>
                    <option value="1">2</option>
                    <option value="2">3</option>
                </select>
                </div>
            </div>
            <div class="col-12 align-items-end justify-content-end d-flex">
                <b-button variant="warning" class="w-100"><i class="fa fa-file mr-3"></i>PDF Çıktı Al</b-button>
            </div>
            </div>
          <template #modal-footer="{ ok, cancel, hide }">
          <b-button size="sm" variant="success" @click="ok()">
          Tamam
           </b-button>
      </template>
        </b-modal>
        
      </div>
    </div>
  </template>
  
  <style lang="scss" scoped>
  @media (max-width: 990px) {
    .search-list-area {
      flex-direction: column;
      .search-input {
        width: 100%;
        * {
          width: 100%;
        }
      }
    }
    .search-buttons {
      flex-direction: column;
    }
  }
  
  .badges-area {
    display: flex;
    gap: 1rem;
    p {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: $dveb-primary !important;
    }
    .badges {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      .badge {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        padding: 0 !important;
  
        &.green {
          background-color: #44b6ae;
        }
        &.yellow {
          background-color: #ffac00;
        }
        &.red {
          background-color: red;
        }
      }
    }
  }
  .tapu-text {
    span {
      background-color: $dveb-divider;
      padding: 0.2rem 0.5rem;
      border-radius: 0.3rem;
    }
  }
  </style>
  
  <script>
  import { mapGetters } from "vuex";
  import moment from "moment";
  
  export default {
    name: "customerList",
  
    data() {
      return {
        search: "",
        filters: "",
        showInsertUpdate: false,
        showDetail: false,
        showImportModal: false,
        resetForm: false,
        confirmCreate: false,
        loading: false,
        badgesEnum: ["green", "yellow", "red"],
        stepNames: ["Hazırlık", "Satış", "Devir"],
        header: [
          { text: "", value: "id", checkboxField: true,size:"50px" },
          { text: "Müşteri Bilgileri", value: "musteriBilgileri"},
          { text: "Telefon Numarası", value: "telefonNumarasi"},
          { text: "E-Posta", value: "eposta"},
          { text: "Gayrimenkulün Açık Adres", value: "gayrimenkulUAVT"},
          { text: "Sigorta Türü", value: "sigortaTuru",size:"200px" },
          { text: "Vekaletname", value: "vekaletname" },
          { text: "Poliçe Oluşturuldu Mu?", value: "IsActive"},
          { text: "Poliçe Detay", value: "policeDetay" },
          { text: "", value: "action",size:"50px" },
        ],
        items: [],
        pagination: {},
        meta: {},
        listSelectedItemIds: [],
        selectedItem: "",
        deleteOperationUrl: "",
        deleteIds: "",
        filterList: [],
        list_length: 5,
        openFullTextModal: false,
      };
    },
    components: {
      Moretext: () => import("@/components/global/Moretext"),
    },
    created() {
      this.getList();
    },
    methods: {
      getList() {
        this.loading = false;
  
        this.items = [
          {
            id: "1",
            telefonNumarasi: "5322587456",
            eposta: "melisa@dveb.com.tr",
            Telefon: "5322162323",
            gayrimenkulUAVT: "İstanbul/Ataşehir",
            sigortaTuru : "Sigorta Türü",
            policeDetay: "",
            IsActive: true,
            vekaletname: true,
          },
         
        ];
        // this.totalItems = 0;
        // this.loading = true;
  
        // let queryLimit = this.list_length ? this.list_length : 10;
        // let query = `musteriler_list?offset=${
        // this.page ? this.page : 0
        // }&limit=${queryLimit}&token=${this.myUser}&searchValue=Kiraci&column=MusteriTipi`;
  
        // this.$ApiService
        // .get(query)
        // .then((res) => {
        //     if (res.status == 200) {
        //     this.items = res.data.result;
        //     this.totalItems = res.data.result?.length;
        //     this.pagination = {
        //         current_page: this.page,
        //         total_pages: 3,
        //         total_items: this.totalItems,
        //     };
        //     }
        // })
        // .catch((err) => {
        //     console.log(err);
        // })
        // .finally(() => {
        //     this.loading = false;
        // });
  
        // this.items = [];
        // this.totalItems = 0;
  
        // let queryLimit = this.perPage ? this.perPage : 10;
        // let query = `user/list?Page=${
        //   this.page ? this.page : 1
        // }&limit=${queryLimit}`;
        // if (this.search) {
        //   query += `&search=${this.search}`;
        // }
  
        // this.$dbFunctions
        //   .get(query)
        //   .then((res) => {
        //     if (res.success == true && res.count > 0) {
        //       this.items = res.data;
        //       this.totalItems = res.count;
  
        //       this.pagination = {
        //         current_page: res.page,
        //         total_pages: res.pageCount,
        //         total_items: res.count,
        //       };
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   })
        //   .finally(() => {
        //     this.loading = false;
        //   });
      },
      updatePage(value) {
        this.meta = value;
  
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
      confirmModal() {
        this.confirmCreate = true;
        setTimeout(() => {
          this.confirmCreate = false;
        }, 200);
      },
      resetInsertUpdateForm(closeInsertUpdate = false) {
        if (closeInsertUpdate) {
          this.closeInsertUpdate();
          this.getList();
        }
  
        this.resetForm = true;
        setTimeout(() => {
          this.resetForm = false;
        }, 200);
      },
      formatDate(date) {
        return moment(date).format("LL");
      },
      closeInsertUpdate() {
        setTimeout(() => {
          this.selectedItem = "";
        }, 100);
        this.showInsertUpdate = false;
      },
      openInsertUpdate(item) {
        this.selectedItem = item;
        this.showInsertUpdate = true;
      },
      closeDetail() {
        this.showDetail = false;
      },
      openDetail(item) {
        this.selectedItem = item;
        this.showDetail = true;
      },
      closeImportModal() {
        this.showImportModal = false;
      },
      openImportModal() {
        this.showImportModal = true;
      },
      openDeleteHandler(item) {
        this.deleteOperationUrl = `musteriler_delete`;
        this.deleteIds = { token: this.myUser, id: item._id.$oid };
        this.$bvModal.show("modal-deleteOperationdeleteCustomer");
      },
      selectedItemIdsChanged(payload) {
        this.listSelectedItemIds = payload;
      },
      actions(payload) {
        if (
          this.listSelectedItemIds === [] ||
          this.listSelectedItemIds.length == 0
        ) {
          this.$generateNotification(
            this,
            "warning",
            "İşlem yapılacak seçili kayıt bulunamadı!",
            "İşleme devam edilemiyor."
          );
        } else {
          if (payload == "allRemove") {
            this.deleteOperationUrl = `user`;
            this.deleteIds = this.listSelectedItemIds;
            this.$bvModal.show("modal-deleteOperation");
          } else if (payload == "allInActive" || payload == "allActive") {
            this.loading = true;
            this.$dbFunctions
              .update(`user/update-status`, {
                ids: this.listSelectedItemIds,
                isActive: payload == "allActive",
              })
              .then((res) => {
                this.$dbFunctions.setResponse(this, res);
                this.getList();
              })
              .catch((err) => {
                this.$dbFunctions.setResponse(this, err);
              })
              .finally(() => {
                this.loading = false;
              });
          } else if (payload == "allExport") {
            this.exportOperations(false);
          }
        }
      },
      exportOperations(onlyHeaders) {
        this.loading = true;
        let payload = {
          Ids: this.listSelectedItemIds,
          onlyHeaders: onlyHeaders,
        };
  
        this.$dbFunctions
          .insertOrUpdate(`user/export`, "export", payload)
          .then((res) => {
            // this.$customFunctions.downloadFile(res.data);
  
            this.$generateNotification(
              this,
              "success",
              `Size özel dosyanız oluşturuldu. <a href="${res.data}" target="_blank" class="text-dark-50 text-hover-dark">Burayı tıklayarak</a> dosyanızı indirebilirsiniz.`,
              "Dosyanız hazır!"
            );
          })
          .catch((err) => {
            this.$dbFunctions.setResponse(this, err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
    watch: {
      meta: {
        handler() {
          this.getList();
        },
        deep: true,
      },
      search: function () {
        this.getList();
      },
      list_length: function () {
        this.getList();
      },
      
    },
    computed: {
      ...mapGetters(["myUser"]),
    },
    components: {
      InsertUpdateOperations: () =>
        import("@/components/systemUser/modals/InsertUpdateOperations"),
      DetailView: () => import("@/components/customers/modals/DetailModal"),
    },
  };
  </script>
  